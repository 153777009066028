@import "../../styles/colors";

.axxes-custom-dropdown {
  min-width: 200px;

  &.UP {
    .axxes-custom-dropdown__list {
      top: -290px !important;
    }
  }

  .axxes-custom-dropdown__list {
    z-index: 99999;

    .axxes-custom-dropdown__list__item {
      background-color: $white;

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }

    .axxes-custom-dropdown__no-selected-item {
      opacity: 0.8;
    }
  }
}
