.axxes-points {
  .extra-small {
    height: 1.2em !important;
  }

  .small {
    height: 1.5em !important;
  }

  .medium {
    height: 2em !important;
  }

  .large {
    height: 2.5em !important;
  }

  .extra-large {
    height: 3em !important;
  }
}
