@import "../../styles/colors";

.create-education {
  .modal__body {
    width: 800px;
    padding-top: 25px;
  }

  .dates {
    display: flex;
    justify-content: space-between;

    input {
      width: 300px;
    }
  }

  input:disabled {
    cursor: not-allowed;
    opacity: 0.4;

    + label {
      opacity: 0.4;
    }
  }

  .max-attendees-tutor {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    height: 150px;

    .max-attendees {
      width: 30%;
      margin-top: 36px;
    }

    .tutor {
      flex-grow: 1;
    }
  }

  .tutor {
    .typeahead input {
      height: 49px;
    }
  }

  .organising-department {
    margin-bottom: 25px;

    label {
      margin-bottom: 0;
    }
  }

  .name-private {
    display: flex;

    .name {
      width: 90%;
    }

    .private-toggle {
      margin-top: 15px;
      margin-left: 15px;
    }
  }

  .fields-hidden {
    display: none;
  }

  .fields-next {
    display: flex;
    gap: 25px;

    > * {
      flex: 0 1 50%;
    }
  }

  .max-attendees {
    width: 30%;
  }

  .create-education-finish {
    text-align: left;

    .education-finish {
      position: relative;

      .education-detail .education-requirements {
        margin-bottom: 10px;
      }

      .education-detail__header-content img {
        max-width: 150px;
      }
    }

    .education-detail {
      overflow-y: auto;
    }

    .submitting,
    .submitted {
      text-align: center;
      overflow: hidden;

      .svg-inline--fa {
        font-size: 8em;
      }
    }

    .submitted {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .checkmark {
        margin-bottom: 30px;
      }
    }

    .education-detail__header {
      h1 {
        max-width: 70%;
      }
    }
  }

  .create-education-thumbnail {
    position: absolute;
    right: 25px;
    bottom: 25px;
    background: $white;

    img {
      max-height: 150px;
      max-width: 300px;
    }
  }
}
