@import "../../styles/colors";

.education-requirements {
  overflow: visible;

  .education-requirements-container {
    max-height: 250px;
    overflow-y: auto;
  }

  .form__input-group {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }

  .no-requirements {
    padding: 0.5rem 0 0 2rem;
    font-size: 12px;
  }

  .axxes-input__field {
    overflow: visible;
  }

  .education-requirement {
    padding: 1rem 1rem 1rem 2rem;
    background-color: $background-color;
    border: 1px solid $grey-lighter;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + .education-requirement {
      border-top: 0;
    }

    .svg-inline--fa {
      font-size: 1.2em;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .new-requirement {
    display: flex;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      display: flex;
      align-items: center;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &.requirements-present {
        border-bottom-right-radius: 0;
      }
    }
  }
}

.ticket-option-requirements {
  overflow: visible;
  margin-bottom: 14px;

  .education-requirements-container {
    max-height: 250px;
    overflow-y: auto;
  }

  .form__input-group {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    display: block;
  }

  .no-requirements {
    padding: 0.5rem 0 0 2rem;
    font-size: 12px;
  }

  .axxes-input__field {
    overflow: visible;
  }

  .new-requirement {
    display: block;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      display: flex;
      align-items: center;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &.requirements-present {
        border-bottom-right-radius: 0;
      }
    }
  }

  .rbt-input-wrapper {
    transform: translateY(20px);
  }
  .rbt-input-main {
    transform: translateY(-10px);
  }

  .rbt-token {
    padding: 10px 25px 10px 5px;

    .svg-inline--fa {
      margin-right: 5px;
    }
  }
}
