@import "../../styles/colors";

.ticket-administration-types {
  margin-bottom: 24px;

  .administration-category {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ticket-administration-icon {
      margin-right: 12px;
    }

    &.inactive {
      opacity: 0.5;
    }

    .form__input-group {
      margin: 0;
    }
  }
}

.ticket-handling-legend {
  margin-bottom: 12px;
}

.approval-booking-user {
  padding: 6px 12px;
  border-left: 1px solid $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + .approval-booking-user {
    margin-top: 6px;
  }

  .approval-booking-user-info {
    display: flex;
    align-items: center;

    .axxes-badge {
      margin-left: 12px;
    }
  }

  img {
    width: 35px;
    height: 35px;
    margin-right: 12px;
    border-radius: 50%;
  }

  .axxes-badge {
    &.APPROVED {
      background-color: $green;
    }
  }

  .approval-booking-user__actions {
    > div {
      .axxes-button__container {
        display: inline-block;
      }
    }

    .axxes-button,
    .axxes-button--ghost {
      &.cancel,
      &.approve {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0px;
      }

      &.reject,
      &.confirm {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.approve {
        background-color: $green;
        border-color: $green;
      }

      &.reject {
        background-color: $signal-red;
        border-color: $signal-red;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.ticket-administration {
  .legend {
    margin-top: 24px;

    > div + div {
      margin-top: 12px;
    }

    .ticket-administration-icon {
      width: 35px;
      display: inline-block;
    }
  }

  .modal {
    .modal__body {
      overflow-y: auto !important;
    }
  }

  .toggles {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .department-toggle {
      display: flex;
      gap: 10px;
      align-items: flex-start;
      margin-bottom: 10px;

      span {
        font-weight: bold;
      }
    }
  }

  .ticket-administration-lanes {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    .close-ticket-option {
      background-color: $green;
      border-color: $green;

      &:hover {
        opacity: 0.6;
      }
    }

    .done-ticket {
      & + .done-ticket {
        margin-top: 6px;
      }
    }

    .book-before {
      color: $signal-red;
      font-size: 0.9em;
    }

    .ticket-handling {
      .education-ticket-handling {
        padding-bottom: 12px;

        .axxes-card + .axxes-card {
          margin-top: 6px;
        }

        .education-ticket-handling-header {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          h4 {
            margin: 0;
            text-transform: none;
          }

          .axxes-badge {
            margin-right: 12px;
          }
        }

        & + .education-ticket-handling {
          margin-top: 12px;
          padding-top: 12px;
          border-top: 1px solid $grey;
        }
      }
    }

    > div {
      background-color: $ultra-light-grey;
      min-height: 100px;
      flex: 1 1 0px;
      padding: 10px 15px;

      .multiple-ticket-option {
        .collapsable-card-subtitle {
          margin-top: -15px;
          margin-bottom: 15px;
        }

        .collapsable-card-content.hidden {
          display: none;
        }

        .ticket-options-container {
          display: flex;
          justify-content: flex-end;

          .ticket-options {
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;

            .ticket-option {
              padding: 12px 6px 12px 12px;
              border-left: 1px solid $primary;

              h5 {
                margin-bottom: 6px;
              }

              &:nth-child(2n + 1) {
                background-color: $ultra-light-grey;
              }

              .book-before {
                text-align: right;
              }
            }
          }
        }
      }

      .single-ticket-option {
        .axxes-card__header {
          flex-direction: column;
        }
      }

      .axxes-card {
        padding: 10px 15px !important;

        .axxes-card__header {
          margin-bottom: 6px;

          h4 {
            margin-bottom: 6px;
          }
        }

        .department {
          font-size: 15px;
          padding: 4px 8px;
        }

        .split-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
