@import "../../styles/colors";

.settings-page {
  main {
    display: grid;
    grid-template-areas: "aside article article article";
  }

  aside {
    grid-area: aside;
    min-width: 300px;
    position: relative;
    padding-top: 25px;

    .extra-user-info {
      border: 1px solid $border-color;
      padding: 25px;
      border-radius: 3px;
      background-color: $white;

      .user-info-block {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 15px;
        }

        .icon {
          width: 20px;
          font-size: 1.3em;
          flex: 0 0 40px;
        }
      }

      .user-info-block {
        .values {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .roles,
      .departments {
        .value {
          &:not(:last-of-type) {
            &::after {
              content: ",";
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  article {
    grid-area: article;
    padding: 25px;

    .content {
      padding: 25px;
      background-color: $white;
      border: 1px solid $border-color;
      border-radius: 3px;
    }
  }
}

@media screen and (max-width: 865px) {
  .settings-page {
    main {
      display: block;

      .user {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        margin-bottom: 15px;
        padding-top: 0;

        .profile-picture {
          position: relative;
          top: 0;
        }
      }

      h2 {
        display: none;
      }

      article {
        padding: 0;
        margin-top: 15px;
      }
    }
  }
}
