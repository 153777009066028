@import "../../styles/colors";

.edit-education {
  .modal__content {
    padding-right: 10px;
  }
  .modal__body {
    width: 800px;
    padding-top: 25px;

    .form__content {
      padding-right: 5px;

      .form__input-group {
        margin-right: 16px;
      }

      &::-webkit-scrollbar {
        width: 12px;
        margin-left: 2px;
      }
      &::-webkit-scrollbar-track {
        background: $ultra-light-grey;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $light-grey;
        border-radius: 20px;
        border: 3px solid $ultra-light-grey;
      }
    }
  }

  .markdown-preview {
    max-height: 300px;
    overflow-y: auto;
  }

  .modal__footer {
    padding-top: 15px;
    margin-right: 25px;
    background-color: $white;
  }

  .max-attendees {
    width: 35%;
  }

  .dates {
    display: flex;
    justify-content: space-between;

    input {
      width: 300px;
    }
  }

  .edit-category {
    padding: 25px 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid $border-color;
    }
  }

  .organising-department {
    margin-bottom: 30px;
  }

  .create-education-finish {
    text-align: left;

    .submitting,
    .submitted {
      text-align: center;

      .svg-inline--fa {
        font-size: 8em;
      }
    }

    .submitted {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .checkmark {
        margin-bottom: 30px;
      }
    }
  }
}
