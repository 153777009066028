.notfound-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .container {
    display: flex;
    width: 80vw;
    align-items: center;
    justify-content: space-between;
  }

  img {
    min-width: 70%;
  }

  h1 {
    font-size: 3em;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .notfound-page {
    .container {
      width: 50vw;
    }

    h1 {
      font-size: 5em;
    }
  }
}
