@import "../../../styles/colors";

.education-waiting-list {
  padding-bottom: 25px;

  .page-container {
    background-color: $white;
    padding: 25px;
  }

  .education-waiting-header {
    display: flex;
    align-items: center;

    h1 {
      margin-bottom: 0;
    }
  }

  .education-waitinglist-container {
    display: flex;
    gap: 15px;

    .waiting-user {
      border-left: 2px solid $primary;
      padding: 15px;
      flex: 1 0 20%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgb(245, 245, 245);

      h4 {
        margin: 0;
      }
    }
  }
}
