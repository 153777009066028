@import "../../../styles/colors";

.education-management {
  .educations-filter {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .axxes-input__container {
      max-width: 50%;
    }

    .axxes-custom-dropdown {
      max-width: 30%;
    }

    .education-toggle {
      margin-left: 15px;
      display: flex;
      padding-top: 15px;

      > span {
        margin: -3px 15px;
        font-weight: bold;
        font-size: 0.8em;
      }
    }
  }

  .expenses-modal {
    .split-content {
      display: flex;
      gap: 25px;

      .expenses-form {
        width: 40%;
      }
    }

    .expense-ticket-selection {
      margin-bottom: 25px;
    }

    .all-expenses {
      max-height: 500px;
      overflow-y: auto;
      flex: 1;

      .education-expense {
        border-left: 2px solid $primary;
        padding: 5px 15px;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          margin: 0;
        }
      }
    }
  }

  .copy-education {
    footer {
      margin-top: 75px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  .ticket-options-modal {
    display: flex;
    gap: 25px;

    .ticket-option-form {
      width: 55%;
    }

    .edit-buttons {
      display: flex;
      gap: 10px;
    }

    .loader-skeleton {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 450px;
    }

    .ticket-option {
      border-left: 2px solid $primary;
      padding: 5px 15px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        margin: 0;
      }

      .ticket-option__duedate {
        color: $signal-red;
      }

      .credits {
        font-size: 1.5em;
        display: flex;
        align-items: center;
      }
    }

    .all-ticket-options {
      max-height: 500px;
      overflow-y: auto;
      flex: 1;
    }

    .ticket-options {
      padding-top: 10px;

      .fields-next {
        display: flex;
        gap: 25px;

        > * {
          flex: 0 1 50%;
        }
      }

      .education-variant {
        padding: 25px 0;

        &:not(:last-of-type) {
          border-bottom: 1px solid $border-color;
        }

        .form__input-datepicker {
          overflow: visible;
        }

        .react-datepicker-popper {
          transform: translate(48px, 0) !important;
        }
      }
    }
  }

  table {
    width: 100%;

    thead tr th {
      border-bottom: 1px solid $grey-lighter;
      padding-bottom: 15px;
    }

    tr {
      &.PUBLISHED {
        .education__name .education--unpublished {
          display: none;
        }
      }

      &.UNPUBLISHED {
        .education__name .education--finished {
          display: none;
        }
      }

      &.attendees-reached {
        td {
          background-color: rgb(255, 255, 226);

          &.attendees {
            font-size: 1.5em;
            font-weight: bold;

            a {
              color: $signal-red;
            }
          }
        }
      }

      &:hover {
        td {
          background-color: $grey-lighter;
        }
      }

      &.enrollment-past {
        td {
          background-color: $red-light;
        }
      }

      .education-state {
        .axxes-badge.PUBLISHED {
          background-color: $green;
        }
      }
    }

    td {
      padding: 15px 0 15px 5px;
      border-bottom: 1px solid $grey-lighter;

      &.education__name {
        max-width: 450px;
      }

      .profile-picture {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 15px;
      }

      &.enrollment-past {
        color: $signal-red;
        font-weight: bold;
      }

      a:visited {
        color: $text-color;
      }

      &.education__name {
        .education--finished {
          color: $green;
        }

        .svg-inline--fa {
          margin-left: 15px;
        }

        a:visited {
          color: $text-color;
        }

        a:hover {
          color: $primary;
        }
      }
    }

    .no-users-found {
      padding-top: 15px;
      border-bottom: 0;
    }

    .credits-column {
      text-align: center;
    }

    .education-action {
      margin-left: 15px;
      cursor: pointer;
      font-size: 16px;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .education-management {
    table {
      td,
      th {
        &.dates-column {
          display: none;
        }
      }
    }
  }
}
