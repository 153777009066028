.educations-overview-container {
  margin: 0 auto;
  transition: width 0.3s ease;
}

@media screen and (max-width: 1250px) {
  .educations-overview-container {
    width: 100%;
  }
}
