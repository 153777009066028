@import "../../styles/colors";

.education-timeline {
  display: flex;
  text-align: left;
  align-items: flex-start;
  padding-left: 120px;

  .education-timeline__timeline {
    .education-timeline__timeline-entry {
      padding: 10px 10px;
      position: relative;
      display: flex;
      align-items: center;

      .education-timeline__timeline-entry__image {
        flex: 0 0 60px;
        margin-right: 15px;
      }

      .education-timeline__timeline-entry__date {
        position: absolute;
        left: -140px;
      }

      .education-timeline__timeline-entry__content {
        flex-grow: 1;
      }

      h3 {
        margin-bottom: 0;

        a {
          color: $text-color;
        }
      }

      .dates,
      .location,
      .recup-units {
        font-size: 0.9em;
        color: $medium-grey;

        .fa-calendar-alt,
        .fa-map-marker-alt,
        .fa-clock {
          margin-right: 10px;
        }
      }

      .fa-circle {
        color: $primary;
        font-size: 20px;
        transform: translate(-106px, 5px);
        position: absolute;

        &.history {
          color: $border-color;
        }
      }

      .unsubscribe {
        width: 120px;
      }
    }

    .education-timeline__timeline-with-date {
      border-left: 3px solid $border-color;
      padding-left: 10px;
    }
  }
}

@media screen and (max-width: 865px) {
  .education-timeline {
    padding-left: 0;

    .unsubscribe {
      display: none;
    }
  }
}
