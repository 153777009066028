@import "../../styles/colors";

.consultant-management {
  .consultant-container {
    .consultant-filter {
      max-width: 30%;
      margin-bottom: 15px;
    }
  }

  .education-plan {
    margin-bottom: 10px;
  }

  .consultants-mobile {
    display: none;

    .consultant-mobile {
      border-left: 2px solid $primary;
      padding: 5px 15px;
      margin-bottom: 15px;

      .credits {
        display: flex;
        align-items: center;

        b {
          margin-right: 5px;
        }
      }

      .actions {
        margin-top: 15px;

        .option-menu {
          .options {
            left: 0;
          }
        }
      }
    }
  }

  table {
    width: 100%;

    thead tr th {
      border-bottom: 1px solid $grey-lighter;
      padding-bottom: 15px;

      .credits-header-wrapper {
        .total-credits {
          margin-right: -20px;
        }

        img {
          transform: translateX(28px);
        }
      }
    }

    tr.credits-negative {
      background-color: rgb(250, 208, 208);
    }

    td {
      padding: 15px 10px;
      border-bottom: 1px solid $grey-lighter;

      .consultant-name {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .no-users-found {
      padding-top: 15px;
      border-bottom: 0;
    }

    .credits-column {
      text-align: right;
    }
  }

  .credit-history {
    table {
      margin-bottom: 45px;

      .date-column,
      .credits-column {
        width: 20%;
      }
    }
  }

  .assignment-message {
    margin-bottom: 75px;
  }
}

@media screen and (max-width: 865px) {
  .consultant-management {
    .assign-credits {
      display: none;
    }

    .consultant-container {
      .consultant-filter {
        max-width: 100%;
      }

      table {
        display: none;
      }

      .consultants-mobile {
        display: block;
      }
    }
  }
}
