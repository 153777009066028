@import "../../../styles/colors";

.education-page {
  padding-top: 25px !important;

  .education__add {
    color: $white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 1.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999999;
    padding: 0;

    .svg-inline--fa {
      margin-right: 0;
    }
  }

  .education-container {
    margin: 0 auto;
    transition: width 0.3s ease;

    h1 {
      margin: 0;
    }
  }

  .page-title {
    display: flex;
    margin-bottom: 25px;
    justify-content: flex-end;

    .education-actions {
      display: flex;

      .manage-action {
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .education-page {
    .education-container {
      width: 100%;

      .my-educations {
        display: none;
      }

      .all-educations {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 865px) {
  .education-page {
    .page-title {
      display: none;
    }

    .education-container {
      .all-educations {
        h1 {
          display: none;
        }

        .education-list-container {
          .extra-filters-total {
            display: none;
          }

          .education-list__header__toggle-wrapper {
            display: none;
          }
        }
      }
    }
  }
}
