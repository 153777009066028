@import "../../styles/colors";

.option-menu {
  position: relative;

  .options {
    position: absolute;
    right: 0;
    width: 250px;
    background-color: $white;
    box-shadow: 0px -1px 25px 0px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    border-radius: 3px;

    .option {
      padding: 15px 25px;
      cursor: pointer;
      text-align: right;

      &.left {
        text-align: left;
      }

      &:hover {
        background-color: $primary-light;
      }
    }
  }
}
