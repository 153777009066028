.education-type,
.education-status {
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 0.9em;
  text-align: center;

  &.OPEN {
    background-color: $green;
    color: $white;
  }

  &.CLOSED {
    background-color: $signal-red;
    color: $white;
  }

  &.CONFIRMED {
    background-color: $blue-light;
  }
}

.education-type {
  background-color: $tertiary;
  color: $white;
}
