@import "../../styles/colors";

.education-detail {
  margin-bottom: 25px;
  position: relative;

  .education-detail__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .education-detail__header-content {
      display: flex;
      align-items: center;

      img {
        max-width: 200px;
        margin-right: 15px;
      }

      .title-bookmark {
        display: flex;
        align-items: center;
      }
    }

    h1 {
      display: flex;
      align-items: center;
      margin: 0px;

      .enrolled-status {
        color: $green;
      }

      .svg-inline--fa {
        margin-left: 5px;
        font-size: 0.8em;
      }
    }

    .axxes-star {
      margin-left: 15px;
    }

    > div {
      min-width: 200px;

      .education-type {
        float: right;
      }
    }

    &-status {
      font-style: italic;
      text-transform: lowercase;
      color: $medium-grey;
    }
  }

  a {
    color: $link-color;

    span {
      overflow-wrap: anywhere;
    }
  }

  .education-requirements {
    margin-top: 10px;

    .requirement {
      padding: 1rem 1rem 1rem 2rem;
      background-color: $background-color;
      border: 1px solid $grey-lighter;
      display: flex;
      align-items: center;

      & + .requirement {
        border-top: 0;
      }
    }
  }

  h4 {
    font-size: 0.9em;
    margin: 0;
    color: $medium-grey;
  }

  .education-detail-tags {
    h3 {
      margin: 0;
      font-size: 1.2em;
    }

    .education-detail-tags__content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .education-extra-info {
    margin-top: 15px;
  }

  .education-detail-variants {
    margin-top: 15px;
    max-width: 860px;

    h4 {
      margin: 0;
    }

    table {
      width: 100%;

      td {
        border: 1px solid $border-color;
        padding: 10px 20px;
      }
    }

    .variant {
      border-left: 2px solid $primary;
      padding: 5px 15px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content {
        h4 {
          color: $text-color;
          font-size: 1.1em;
        }

        .latest-enrollment-variant {
          color: $signal-red;
        }
      }

      .variant__credits {
        font-size: 2em;
        display: flex;
        align-items: center;
      }
    }
  }

  .education-detail-description {
    font-size: 1.2em;
  }

  .education-detail-credits {
    font-size: 2em;
    display: block;
    align-items: center;

    img {
      margin-left: 5px;
    }
    b {
      font-size: 1.5em;
      margin-top: -10px;
    }
  }

  .education-detail-dates,
  .education-detail-status {
    .svg-inline--fa {
      margin-right: 5px;
    }
  }

  .education-detail-duedate {
    color: $signal-red;

    .svg-inline--fa {
      margin-right: 5px;
    }
  }

  .education-detail__header-tags,
  .education-detail__header-departments {
    .axxes-badge {
      margin-right: 5px;
      background-color: $secondary;
      margin-bottom: 5px;
    }

    .axxes-badge.department {
      background-color: $primary;
    }
  }

  .education-tutor + .education-attendees {
    margin-left: 15px;
  }
}

@media screen and (max-width: 865px) {
  .education-detail {
    .axxes-card__header .education-detail__header {
      max-width: 100%;

      .education-detail__header-content {
        display: block;

        h1 {
          line-height: 1.5em;
        }
      }
    }

    .education-detail-tags {
      .education-detail-tags__content {
        display: block;
      }
    }
  }
}
