@import "../../styles/colors";

.axxes-footer {
  overflow: hidden;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 120px;
    }

    span {
      font-size: 0.8em;
    }
  }
}

@media screen and (max-width: 600px) {
  .axxes-footer {
    display: none !important;
  }
}
