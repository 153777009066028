@import "../../styles/colors";

.video__icon {
  position: relative;
  box-sizing: border-box;
  transform-origin: center;

  *::after,
  *::before {
    box-sizing: border-box;
  }

  .circle--outer {
    border: 1px solid $green;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 auto 5px;
    position: relative;
    opacity: 0.8;
    -webkit-animation: circle 2s ease-in-out infinite;
    animation: circle 2s ease-in-out infinite;
    transform-origin: center;
    left: 5px;
  }

  .circle--inner {
    background: $green;
    left: 15px;
    top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    opacity: 0.8;

    &:after {
      content: "";
      display: block;
      border: 2px solid $green;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      top: -4px;
      left: -4px;
      position: absolute;
      opacity: 0.8;
      -webkit-animation: circle 2s ease-in-out 0.2s infinite;
      animation: circle 2s ease-in-out 0.2s infinite;
    }
  }

  p {
    color: $text-color;
    text-align: center;
    margin: 0;
    padding-left: 7px;
    font-weight: bold;
  }
}

@-webkit-keyframes circle {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes circle {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
