.user-enrollment {
  .modal__content {
    width: 700px;
  }

  .department {
    .typeahead input {
      height: 55px;
    }
  }
}
