@import "../styles/colors";
@import "~react-bootstrap-typeahead/css/Typeahead.scss";
@import "~react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

.form__input-group {
  text-align: left;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  &.form__inline {
    display: inline-flex;
  }

  input.form-input,
  textarea.form-input {
    border: 1px solid $border-color;
  }

  input.form-input {
    padding: 20px 15px;
    height: 25px;
  }

  select.form-input {
    padding: 10px 15px;
  }

  input.form-input,
  select.form-input {
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    color: $text-color;
    font-size: 14px;

    &:focus {
      outline: 0;
      border-color: $tertiary;
      box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  label {
    font-weight: bold;
  }

  .form__input-required {
    font-size: 1.8em;
    transform: translateY(100px);
  }

  .form-input {
    &.invalid {
      box-shadow: inset 0 1px 1px rgba(255, 0, 0, 0.3),
        0 0 5px rgba(255, 0, 0, 0.6);
    }
  }

  .form__input-datepicker {
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: $primary;
    }

    .react-datepicker-popper {
      z-index: 2;
    }
  }

  .form__input-error {
    color: $signal-red;
    margin-top: 10px;
  }

  textarea.form-input {
    resize: none;
  }

  .form__input-type-selector-options {
    margin-top: 5px;

    button {
      border-radius: 0;
      border-right: 0;
      text-transform: uppercase;
      font-size: 14px;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &.selected {
        background-color: $secondary;
        color: $white;
        border-color: $secondary;
      }

      &:hover {
        background-color: $secondary;
        color: $white;
        border-color: $secondary;
      }
    }

    & + .form__input-error {
      margin-top: 5px;
    }
  }

  .typeahead {
    .rbt-input {
      border-color: transparent transparent transparent var(--axxes-grey);
      border-left: 2px solid var(--axxes-grey);
      background-color: var(--axxes-light-background);

      &:hover,
      &.focus {
        border-color: transparent transparent transparent $primary;
        box-shadow: none;
      }
    }

    .rbt-menu {
      .dropdown-item {
        .TYPE {
          color: $tertiary;
        }
        .DEPARTMENT {
          color: $secondary;
        }
        &:hover {
          .TYPE,
          .DEPARTMENT {
            color: $white;
          }
        }
      }
    }

    .rbt-token {
      border: 2px solid transparent;

      &.TYPE {
        background-color: $tertiary !important;
      }
      &.DEPARTMENT {
        background-color: $primary !important;
      }
      &.TAG {
        background-color: $medium-grey !important;
      }
      &.CUSTOM {
        background-color: $blue-light !important;
      }
      &.rbt-token-active {
        border-color: $signal-red;
      }
    }

    .form-control {
      border-radius: 0;
      padding: 1rem 16px;
      font-size: 14px;
    }

    .dropdown-item {
      padding: 1rem 2rem;
      color: $text-color !important;

      &.active,
      &:hover {
        background-color: $primary;
        color: $white !important;
      }
    }

    .rbt-input-wrapper {
      .rbt-token {
        background-color: $primary;
        color: $white;
        height: 25px;
        line-height: 25px;
        vertical-align: middle;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .files {
    position: relative;

    &:before {
      position: absolute;
      bottom: 10px;
      left: 0;
      pointer-events: none;
      width: 100%;
      right: 0;
      height: 57px;
      content: " or drag it here. ";
      display: block;
      margin: 0 auto;
      color: #2ea591;
      font-weight: 600;
      text-transform: capitalize;
      text-align: center;
    }

    &:after {
      pointer-events: none;
      position: absolute;
      top: 30px;
      left: 0;
      width: 50px;
      right: 0;
      height: 56px;
      content: "";
      background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
      display: block;
      margin: 0 auto;
      background-size: 100%;
      background-repeat: no-repeat;
    }

    input {
      outline: 2px dashed #92b0b3;
      outline-offset: -10px;
      -webkit-transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
      transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
      padding: 120px 0px 85px 35%;
      text-align: center !important;
      margin: 0;
      width: 100% !important;
    }
  }
}

.axxes-button[disabled],
.axxes-input__field[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.axxes-input__field[disabled] {
  border-color: transparent transparent transparent var(--axxes-grey) !important;
}
