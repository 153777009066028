@import "../../styles/colors";

.educations-overview-container {
  display: flex;

  aside {
    .collapsable-card {
      width: 400px;
    }

    .simple-education-star {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        margin: 0;
        font-size: 1.2em;
      }

      .axxes-star {
        margin-left: 15px;
      }
    }

    .fep-balance {
      &.negative {
        .axxes-card {
          background-color: $red-light;
        }
      }

      .fep-balance-year {
        color: $grey;
      }

      .fep-balance-credits {
        display: flex;

        > div {
          flex-grow: 1;
        }

        h1 {
          margin-bottom: 0;
          font-size: 3em;
          font-weight: normal;
        }

        .title {
          color: $primary;
        }
      }
    }

    .fep-calendar {
      .react-calendar {
        border: 0;

        .react-calendar__tile--hasActive,
        .react-calendar__tile--range {
          background: $blue-light;
          color: black;
        }

        .react-calendar__tile--active,
        .active__education,
        .react-calendar__tile:hover {
          background: $primary-light;
        }

        .react-calendar__tile--now,
        .active__education:hover {
          background: $primary;
        }
      }

      .fep-calendar-selecteddate {
        color: $medium-grey;
      }

      .fep-calendar-mini-education {
        margin-top: 10px;
        display: flex;
        align-items: center;
        overflow: hidden;

        h3 {
          margin-bottom: 0;
          max-width: 310px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          a {
            color: $text-color;
          }
        }

        .fep-calendar-mini-education-content {
          display: flex;
          justify-content: center;
          flex-direction: column;

          .fep-calendar-mini-education-content-location {
            color: $medium-grey;
          }

          .fep-calendar-mini-education-content-url {
            .fa-globe-europe {
              margin-right: 5px;
            }
          }
        }

        img {
          height: 50px;
          margin-right: 10px;
        }

        .fa-map-marker-alt {
          margin-right: 5px;
        }
      }
    }
  }

  .fep-calendar-mini-education {
    border-left: 2px solid $primary;
    padding: 5px 15px;

    a:visited {
      color: $text-color;
    }
  }

  main {
    flex-grow: 1;
    padding-right: 15px;

    .adhoc-educations {
      .fep-calendar-mini-education {
        display: flex;
        align-items: center;

        h3 {
          margin-bottom: 0;

          a {
            color: $text-color;
          }
        }

        .fep-calendar-mini-education-image {
          flex: 0 0 125px;
          margin-right: 15px;
        }

        .fa-globe-europe {
          margin-right: 5px;
        }
      }
    }
  }

  .scrollable-content {
    overflow-y: auto;
    max-height: 60vh;
  }
}

@media screen and (max-width: 865px) {
  .educations-overview-container {
    display: block;

    aside {
      .collapsable-card {
        width: 100% !important;
      }
    }

    main {
      padding-right: 0;
    }
  }
}
