@import "../../styles/colors";

.education-plan__barchart {
  .chart {
    display: block;
    position: relative;
    height: 50px;
    width: 100%;
    background-color: $light-grey;
    margin-bottom: 35px;
    margin-top: 15px;
    border-radius: 10px;
  }

  .costzero,
  .costfull {
    position: absolute;
    bottom: -22px;
  }

  .costzero {
    left: 0;
    top: -22px;
  }

  .costfull {
    right: 0;
    top: -22px;
  }

  .education {
    float: left;
    position: relative;
    height: 50px;

    &:first-of-type {
      border-radius: 10px 0 0 10px;
    }

    .cost {
      position: absolute;
      right: 0;
      bottom: -22px;
    }
  }

  .education-plan__barchart-legend {
    .education-legend {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .color {
        height: 15px;
        width: 15px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
  }
}
