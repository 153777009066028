$primary: var(--axxes-accent);
$secondary: var(--axxes-primary);

$primary-light: var(--axxes-accent--light);

$grey-dark: var(axxes-grey--darker);
$grey-darker: var(axxes-grey--dark);
$grey: var(--axxes-grey);
$grey-lighter: var(--axxes-grey--lighter);
$grey-light: var(--axxes-grey--light);

$blue-light: var(--axxes-blue--light);
$blue: var(--axxes-blue);
$blue-dark: var(--axxes-blue--dark);

$red-light: #ffd3d3;

$red-light: rgb(255, 235, 235);

$white: #ffffff;
$near-white: #f9fafa;
$black: #000000;
$near-black: #303333;

$background-color: var(--axxes-light-background);

// Custom colors
$ultra-light-grey: #f3f3f3;
$light-grey: #dfdfdf;
$grey: #b7b7b7;
$medium-grey: #777;
$dark-grey: #4d4d4d;

$text-color: #1a1a1a;
$border-color: #ccc;

$link-color: #1279ee;
$signal-red: #c52727;
$green: #369957;

$tertiary: #006e61;
