.axxes-star {
  font-size: 1.7em;
  color: rgb(255, 219, 118);
  cursor: pointer;

  &:not(.starred) {
    &:hover {
      .fa-star.empty {
        display: none;
      }
      .fa-star.solid {
        display: block;
      }
    }
  }

  &.starred {
    &:hover {
      .fa-star.empty {
        display: block;
      }
      .fa-star.solid {
        display: none;
      }
    }
  }

  .fa-star.solid {
    display: none;
  }

  &.starred {
    .fa-star.solid {
      display: block;
    }
    .fa-star.empty {
      display: none;
    }
  }
}
