@import "../../styles/colors";

.ticket-administration-icon {
  font-size: 1.4em;
  color: $dark-grey;

  + .ticket-administration-icon {
    margin-left: 12px;
  }

  &.UNORDERED {
    color: $grey;
  }

  &.ORDERING {
    color: $primary;
    opacity: 0.8;
  }

  &.ORDERED {
    color: $green;
    position: relative;

    .fa-check-circle {
      font-size: 0.7em;
      bottom: -3px;
      right: -3px;
      position: absolute;
    }
  }
}

.rbt-input-wrapper {
  .ticket-administration-icon {
    color: $white !important;
  }
}
