@import "../../styles/colors";

.login-page {
  background-color: $dark-grey;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-container {
    background-color: $white;
    min-width: 100%;

    .axxes-logo {
      width: 150px;
    }

    .login-container__title {
      background-color: $dark-grey;
      color: $white;
      padding: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 150px;
      }

      h1,
      h3 {
        color: $white;
      }

      h1 {
        text-transform: uppercase;
      }

      div {
        text-align: center;
        color: $white;
      }
    }

    .login-container__login {
      background-color: $light-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 35px;

      .login-container__login-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $white;
        padding: 5px 10px;
        margin-top: 15px;
        border: 1px solid $medium-grey;

        &:hover {
          opacity: 0.8;
        }

        img {
          height: 20px;
          margin-right: 10px;
        }
      }
    }

    .login-form {
      text-align: left;
    }
  }
}

@media screen and (min-width: 768px) {
  .login-page {
    background: white url("./axxes-office.jpg") no-repeat;
    background-size: cover;

    .login-container {
      display: flex;
      flex-direction: row;
      min-width: 90%;

      .login-container__block {
        width: 50%;
      }

      .login-container__title {
        img {
          width: 50%;
        }
      }
    }
  }
}

@media screen and (min-width: 1460px) {
  .login-page {
    .login-container {
      min-width: 50%;

      .login-container__title {
        padding: 100px 35px;
        flex-direction: column;

        img {
          height: 250px;
        }
      }
    }
  }
}
