.administration-page {
  .axxes-card__content {
    display: flex;

    .axxes-button__container + .axxes-button__container {
      margin-left: 15px;
    }
  }

  .export-section {
    .app-spinner {
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
  }
}
