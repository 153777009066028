@import "../../styles/colors";

.axxes-mobile-header {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9999999;

  .axxes-header__bottombar__nav {
    display: flex;
    background-color: $white;
    box-shadow: 0px -1px 25px 0px rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
  }

  a {
    color: $text-color !important;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    min-height: 50px;
    align-items: center;
    font-size: 1.2em;
    background-color: $white;
  }
}

.axxes-header {
  position: fixed;
  top: 0;
  z-index: 999999;
  background-color: $white;

  .axxes-header__topbar {
    box-shadow: 0px -1px 25px 0px rgba(0, 0, 0, 0.3);
    grid-template-columns: 2fr 1fr auto;

    .axxes-header__topbar__nav {
      > a,
      > span {
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 4px solid transparent;
        transition: border-color 0.3s ease-out;
        padding: 0 12px;
        opacity: 1;
        text-decoration: none;
        color: $text-color;

        span {
          margin-bottom: -4px;
        }

        &:not(.axxes-header__topbar__logo) {
          &:hover,
          &.active {
            border-color: $primary;
          }
        }
      }
    }

    .axxes-header__topbar__user {
      .user-dropdown {
        .option-menu .axxes-button--subtle {
          padding: 0;

          &:hover {
            background-color: transparent;
          }
        }

        a:visited {
          color: $text-color;
        }

        .user-image {
          margin-left: 10px;

          .profile-picture {
            &:hover {
              cursor: pointer;
            }
            .initials {
              margin-right: 0;
            }
          }
        }
      }
    }

    .axxes-header__topbar__axxespoints {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 20px;
      img {
        margin-left: 5px;
      }
    }

    .axxes-header__topbar__logo {
      img {
        height: 40px;
      }
    }

    .signout-symbol {
      padding-left: 5px;
      border-left: 1px solid black;
      margin-left: 15px;
      font-size: 1.5em;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 865px) {
  .axxes-header {
    .axxes-header__topbar {
      .axxes-header__topbar__nav .axxes-header__topbar__logo {
        padding: 0;
      }

      .axxes-header__topbar__axxespoints {
        margin-right: 10px;
      }

      .axxes-header__topbar__user {
        margin-right: -1.5em;

        .user-dropdown {
          .user-image {
            margin: 0;
          }

          .greeting {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .axxes-header {
    display: none !important;
  }

  .axxes-mobile-header {
    display: block !important;
  }
}
