@import "../../styles/colors";

.page-header {
  width: 100vw;
  min-height: 250px;
  margin-left: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;
  padding: 25px 15px;

  h1 {
    font-size: 4em;
    color: $white;
  }

  h2,
  h3,
  h4 {
    color: $white;
    font-weight: normal;
  }

  h2 {
    font-size: 3em;
  }

  h3 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.3em;
  }

  .breadcrumbs {
    position: absolute;
    left: 5px;
    bottom: 5px;
  }
}

@media screen and (max-width: 1250px) {
  .page-header {
    margin-left: -10px;
  }
}

@media screen and (max-width: 768px) {
  .page-header {
    display: none;
  }
}
