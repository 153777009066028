@import "../../styles/colors";

.profile-picture {
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .initials {
    background-color: $primary;
    color: $white;
    width: 50px;
    height: 50px;
    display: inline-block;
    line-height: 50px;
    text-transform: uppercase;
    margin-right: 15px;
    text-align: center;
    border-radius: 50%;
    font-size: 1.5em;
    font-weight: bold;
  }

  &.small {
    img,
    .initials {
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 1.2em;
    }
  }

  &.medium {
    img,
    .initials {
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 2em;
    }
  }

  &.big {
    img,
    .initials {
      width: 120px;
      height: 120px;
      line-height: 120px;
      font-size: 3.5em;
    }
  }
}
