@import "../../../styles/colors";

.create-education {
  .create-form {
    background-color: $white;
    padding: 24px;

    .form-block {
      padding-bottom: 24px;
      border-bottom: 1px solid $light-grey;
    }

    .form-block-title {
      margin-top: 36px;
    }

    .tutor {
      .app-label {
        font-size: 12px;
      }

      .typeahead input {
        height: 49px;
      }
    }

    .max-attendees {
      margin-top: 36px;
    }

    .education-requirements {
      margin-bottom: 15px;
    }

    .variants {
      .variants-list {
        max-height: 650px;
        overflow-y: auto;
        padding-bottom: 12px;
      }

      .axxes-card {
        padding: 12px;
        border-left: 1px solid $ultra-light-grey;
        border-right: 1px solid $ultra-light-grey;

        + .axxes-card {
          margin-top: 12px;
        }

        h4 {
          margin-bottom: 0;
        }

        .axxes-card__content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .credits {
            font-size: 1.5em;
            display: flex;
            align-items: center;
          }
        }

        .ticket-option-actions {
          .delete-variant {
            background-color: $signal-red;
            border-color: $signal-red;

            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
    }

    .create-form-actions {
      padding-top: 24px;
      display: flex;
      gap: 12px;
      justify-content: flex-end;
    }
  }
}
