@import "../../styles/colors";

.education-list-container {
  background-color: $white;
  padding: 25px;

  .education-list__header {
    display: flex;

    .education-list__header__toggle-wrapper {
      width: 130px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .axxes-button__container {
        margin-top: 20px;

        &:first-of-type button {
          border-radius: 5px 0 0 5px;
          transform: translateX(2px);
        }
        &:last-of-type button {
          border-radius: 0 5px 5px 0;
        }
      }
    }

    .education-filter-container {
      flex-grow: 1;

      .form__input-group {
        margin-bottom: 0;
      }
    }
  }

  .extra-filters-total {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .education-extra-filters {
    display: flex;
    column-gap: 15px;
    align-items: flex-end;

    .extra-filters-toggle {
      margin-top: 25px;
    }

    form {
      display: flex;
      align-items: center;
      column-gap: 15px;

      .form__input-group {
        margin-bottom: 0;
      }

      .range-slider {
        min-width: 300px;
      }

      .from-to {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }
}
