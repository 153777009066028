@import "../../styles/colors";

.breadcrumbs {
  text-transform: uppercase;
  color: $white !important;

  span {
    &.last {
      max-width: 500px;
      display: inline-block;
    }
  }

  a {
    color: $white !important;

    &:not(:last-child) {
      text-decoration: underline;
    }
  }

  .separator {
    margin: 0 15px;
  }
}
