.education-list__item-container {
  padding: 25px 0;

  &.UNPUBLISHED,
  &.PRIVATE {
    opacity: 0.3;

    .education-list__item-visibility {
      display: inline-block !important;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $border-color;
  }

  .education-list__item-ticket-options {
    padding-left: 245px;
    padding-top: 25px;

    .education-list__item-ticket-options__item {
      border-left: 2px solid $primary;
      padding: 5px 15px;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }

      .education-list__item-ticket-options__item__duedate {
        color: $signal-red;
      }

      .credits {
        font-size: 1.5em;
        display: flex;
        align-items: center;
      }
    }
  }
}

.education-list__item {
  position: relative;
  display: flex;
  color: $text-color;
  text-decoration: none;

  .fa-calendar-alt,
  .fa-user,
  .fa-user-graduate,
  .fa-map-marker-alt,
  .fa-info,
  .fa-info-circle,
  .fa-globe-europe {
    margin-right: 5px !important;
  }

  .education-list__item-image {
    margin-right: 15px;
    flex: 0 0 230px;
    display: flex;
    align-items: center;
  }

  h2 {
    font-size: 1.4em;
    line-height: 1.5em;
    margin-bottom: 5px !important;

    a {
      color: $text-color !important;
    }
  }

  &:hover {
    opacity: 1;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 5px !important;

    a {
      color: $text-color !important;
    }
  }

  .education-list__item-content {
    display: flex;
    flex-grow: 1;
    padding-right: 10%;

    .education-list__item-name {
      margin-bottom: 0;
      margin-top: -10px;

      a {
        color: $text-color;
      }
    }

    .education-list__item-dates {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
    }

    .education-list__item-location {
      margin-bottom: 5px;
    }

    .education-list__item-attendees {
      margin-bottom: 5px;

      &.full {
        color: $signal-red;
      }
    }

    .education-list__item-info-duedate {
      color: $signal-red;
      position: absolute;
      right: 0;
      bottom: 0;

      .svg-inline--fa {
        margin-right: 10px;
      }
    }

    .education-list__item-header__basic-info {
      flex-grow: 1;

      .education-list__item-visibility {
        display: none;
        color: $primary;
        font-size: 0.8em;
        margin-left: 15px;
      }

      .education-tags {
        margin-top: 20px;

        .axxes-badge {
          margin-right: 5px;
          margin-bottom: 5px;
          background-color: $secondary;

          &.department {
            background-color: $primary;
          }

          &.education-type {
            background-color: $tertiary;
          }
        }
      }

      .education-list__item-info-url {
        text-decoration: underline;
        display: inline-block;
        color: $link-color;

        span {
          overflow-wrap: anywhere;
        }
      }
    }

    .education-list__item-multiple-ticket-options {
      position: absolute;
      right: 0;
      top: 0;

      &.no-tickets {
        color: $medium-grey;
      }

      a {
        text-decoration: underline;
        color: $link-color;
        cursor: pointer;
      }
    }

    .education-list__item-credits {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;

      b {
        font-size: 2.5em;
      }

      img {
        margin-left: 5px;
      }
    }
  }
}

.education-list__tile {
  flex-basis: 330px;
  flex-grow: 1;
  position: relative;

  .fa-calendar-alt,
  .fa-user,
  .fa-user-graduate,
  .fa-map-marker-alt,
  .fa-globe-europe {
    margin-right: 5px !important;
  }

  img {
    max-width: 150px;
    max-height: 50px;
  }

  .axxes-card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .education-list__item-multiple-ticket-options {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 1.3em;
    cursor: pointer;
  }

  .education-list__item-credits {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;

    b {
      font-size: 2.5em;
    }

    img {
      margin-left: 5px;
    }
  }

  h1 {
    font-size: 1.3em;
    line-height: 1.5em;
    margin: 10px 0 !important;

    a {
      color: $text-color !important;
    }
  }

  .education-tags {
    margin-top: 20px;

    .axxes-badge {
      margin-right: 5px;
      margin-bottom: 5px;
      background-color: $secondary;

      &.department {
        background-color: $primary;
      }

      &.education-type {
        background-color: $tertiary;
      }
    }
  }

  .education-list__item-info-duedate {
    color: $signal-red;

    .svg-inline--fa {
      margin-right: 10px;
    }
  }
}
