@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~toastr/build/toastr.min.css";
@import "~react-calendar/dist/Calendar.css";
@import "~@axxes/design-system/dist/styles/index.css";

@import "./styles/colors";
@import "./styles/page";
@import "./styles/forms";
@import "./styles/educations";

body {
  margin: 0;
  font-size: 14px !important;
  letter-spacing: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: $text-color;
  transition: color 0.2s ease-in;

  &:hover {
    opacity: 0.8;
    color: $link-color;
  }

  .svg-inline--fa {
    margin-right: 5px;
  }
}

.form-control {
  background-color: transparent;
}

.app-content {
  padding-top: 50px;
  min-height: calc(100vh - 100px);
  overflow-x: hidden;

  .page-header {
    margin-top: -50px;
  }

  .page-padding {
    padding: 56px 100px 0px 100px;
  }
}

.fep-logo {
  height: 60px;
}

h1,
h2,
h3 {
  text-transform: initial !important;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.7em;
}

.highlight {
  color: $primary;
}

.axxes-button.--color-delete {
  background-color: $signal-red;
  border-color: $signal-red;
}

.fep-flex-container {
  display: flex;
}

.axxes-card__header {
  a {
    color: $link-color !important;
  }
}

.axxes-badge {
  vertical-align: middle;
}

textarea {
  min-height: 200px;
  resize: none;
}

.button-icon {
  margin-right: 10px;
}

.page-container {
  margin: 0 auto;
  transition: width 0.3s ease;
}

.axxes-input__container .axxes-input__field .label--float,
.axxes-input__container .axxes-input__field:focus-within label {
  top: 2px !important;
}

.axxes-button--subtle.--color-accent:focus {
  background-color: transparent !important;
  border-color: var(--axxes-accent--light) !important;
  color: $primary !important;
}

.installation-prompt {
  position: fixed;
  bottom: -150px;
  transition: bottom ease-in 0.5s;
  width: 100vw;
  padding: 25px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.dismissed {
    display: none !important;
  }

  .upper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    width: 100%;
    align-items: center;
  }

  img {
    height: 50px;
    margin-right: 10px;
  }

  &.visible {
    bottom: 0;
  }
}

.markdown-preview {
  h1 + h2 {
    margin-top: 15px !important;
  }

  p > code {
    padding: 0 5px;
    color: $primary;
    background-color: rgb(241, 241, 241);
  }

  pre {
    padding: 10px;
    background-color: rgb(241, 241, 241);
  }
}

@media screen and (max-width: 1250px) {
  .page-container {
    width: 100%;
  }

  .app-content .page-padding {
    padding: 56px 10px 0px 10px;
  }
}

@media screen and (max-width: 600px) {
  .app-content {
    padding-top: 0;
    padding-bottom: 65px;

    .page-padding {
      padding: 10px 10px 0px 10px !important;
    }
  }
}

#toast-container {
  .toast {
    opacity: 1;
    z-index: 99999999;
  }

  .toast-success {
    background-color: $green;
  }

  .toast-error {
    background-color: $signal-red;
  }
}

.margin-b-12 {
  margin-bottom: 12px;
}
