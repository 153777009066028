@import "../../styles/colors";

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.overflow-visible {
    .modal__content {
      .modal__body {
        overflow-y: visible !important;
      }
    }
  }

  .modal__backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    height: inherit;
    width: inherit;
  }

  .modal__content {
    min-width: 400px;
    width: auto;
    position: absolute;
    background-color: $white;
    padding: 15px 25px;
    border-radius: 3px;

    &.wider {
      min-width: 600px;
    }

    &.super {
      min-width: 800px;
    }

    &.superwide {
      min-width: 90vw;
    }

    .modal__body {
      padding-top: 5px;
    }

    .modal__footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      button {
        margin-left: 15px;
      }
    }
  }
}

@media screen and (min-width: 865px) {
  .modal .modal__content {
    margin-top: 5vh;

    .modal__body {
      max-height: 75vh;
      overflow-y: auto !important;
    }
  }
}

@media screen and (max-width: 865px) {
  .modal {
    .modal__content {
      width: 99% !important;
      min-width: 0 !important;
      max-height: 85vh;
      top: 2vh;
      overflow-y: auto !important;
    }
  }
}
