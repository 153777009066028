@import "../../styles/colors";

.fep-tabs {
  .fep-tabs__nav {
    transform: translateY(1px);

    .fep-tabs__nav__item {
      display: inline-block;
      padding: 0.5rem 1rem;
      border-top: 2px solid $grey-light;
      border-left: 1px solid $grey-light;
      border-bottom: 1px solid $grey-light;
      cursor: pointer;
      transition: opacity 0.3s ease;
      background-color: $white;

      &:last-child {
        border-right: 1px solid $grey-light;
      }

      &.active {
        border-top-color: $primary;
        border-bottom-color: transparent;
      }

      &:not(.active) {
        opacity: 0.5;

        .fep-tabs__nav__item--action {
          display: none;
        }
      }

      &:hover {
        opacity: 1;
        border-top-color: $primary;
      }

      .fep-tabs__nav__item--action {
        margin-left: 10px;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .fep-tabs__content {
    border: 1px solid $grey-light;
    background-color: $white;
    padding: 24px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  }
}
