@import "../../styles/colors";
@import "../EducationList/education.scss";

.education-slider {
  .educations {
    display: flex;
    gap: 10px;
    position: relative;
  }

  .controls {
    position: absolute;
    width: 105%;
    display: flex;
    top: 45%;
    left: -2.5%;
    justify-content: space-between;

    .left-control,
    .right-control {
      font-size: 3em;
      color: $light-grey;
      transition: color ease 0.3s;
      cursor: pointer;

      &.disabled {
        visibility: hidden;
      }

      &:hover {
        color: $medium-grey;
      }
    }
  }

  .pages {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 15px;

    .page {
      height: 15px;
      width: 15px;
      background-color: $primary-light;
      border-radius: 50%;
      transition: all ease 0.2s;
      cursor: pointer;

      &.active {
        background-color: $primary;
      }
    }
  }
}
