@import "../../../styles/colors";

.education-enrollments {
  padding-bottom: 25px;

  .page-container {
    background-color: $white;
    padding: 25px;
  }

  .education-enrollments-header {
    display: flex;
    align-items: center;

    h1 {
      margin-bottom: 0;
    }
  }

  .user-enrollment {
    margin-left: 15px;
    min-width: 150px;

    .modal .modal__body {
      overflow-y: visible !important;
    }
  }

  .education-enrollments-container {
    .education-ticket-option-header {
      display: flex;
      align-items: center;

      .axxes-button__container {
        margin-left: 25px;
      }
    }

    .education-ticket-option {
      a {
        color: $text-color !important;
      }

      .enrolled-users {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;

        .enrolled-user {
          border-left: 2px solid $primary;
          padding: 15px;
          flex: 1 0 20%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: rgb(245, 245, 245);

          .lower {
            display: flex;
            align-items: center;
          }

          .axxes-badge.department {
            background-color: $primary;
          }

          .credits {
            margin-left: 15px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .education-waitinglist-container {
    display: flex;
    gap: 15px;

    .waiting-user {
      border-left: 2px solid $primary;
      padding: 15px;
      flex: 1 0 20%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgb(245, 245, 245);

      h4 {
        margin: 0;
      }
    }
  }
}
