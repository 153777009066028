@import "../../styles/colors";

.switch {
  .toggle {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  .slider.on {
    background-color: $primary;
  }

  .toggle:focus {
    outline: 0;
    box-shadow: 0 0 2px $link-color;
  }

  .slider.on:before {
    transform: translateX(15px);
  }
}
