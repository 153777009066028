@import "../../styles/colors";

.collapsable-card {
  margin-bottom: 15px;

  .axxes-card__header {
    .fa-caret-up,
    .fa-caret-down {
      cursor: pointer;
    }

    .collapsable-card-title {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      h3 {
        margin: 0;
      }

      .extra-element {
        margin-left: 15px;
      }
    }
  }

  .collapsable-card-content {
    &.hidden {
      .content-body {
        display: none;
      }

      &::after {
        content: "More content available";
        color: $light-grey;
      }
    }
  }
}
