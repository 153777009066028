@import "../../styles/colors";
@import "./education.scss";

.education-list {
  &.tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

@media screen and (max-width: 1300px) {
  .education-list {
    &.list {
      .education-list__item {
        .education-list__item-content {
          display: block;
        }

        .education-list__item-credits,
        .education-list__item-info-duedate {
          position: relative !important;
        }
      }
    }
  }
}

@media screen and (max-width: 865px) {
  .education-list {
    &.list {
      .education-list__item {
        display: block;

        .education-list__item-image {
          display: none;
        }

        .education-list__item-content {
          padding-right: 0;
        }

        .education-list__item-multiple-ticket-options,
        .education-list__item-multiple-ticket-options.no-tickets {
          display: none;
        }

        .education-list__item-credits {
          margin: 5px 0;
        }
      }
    }
  }
}
