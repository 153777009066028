@import "../../styles/colors";

input.disabled {
  color: $medium-grey;
}
input.disabled:hover {
  border-color: inherit;
  cursor: not-allowed;
}

.disabled:hover {
  button:hover {
    background-color: $primary;
    border-color: $primary;
    cursor: not-allowed;
  }
  .selected:hover {
    background-color: var(--axxes-primary);
  }
  cursor: not-allowed;
}

.disabled:hover > div {
  border-color: transparent transparent transparent var(--axxes-grey) !important;
  cursor: not-allowed;
}

.axxes-input__field__checkbox {
  width: auto;
  margin-left: 5px;
}

label.disabled {
  opacity: 0.6;
}

.app-label {
  .form__input-group {
    margin-top: -10px;
    overflow: visible !important;

    label {
      top: 4px;
      font-size: 12px;
      line-height: 0.8rem;
      font-weight: 600;
      z-index: 1;
    }

    .axxes-input__field {
      overflow: visible !important;
    }
  }
}

.form-input__small {
  width: 40%;
}

.markdown-textarea {
  .markdown-api {
    margin-bottom: 15px;
  }

  .markdown-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    .markdown-actions {
      display: flex;

      button {
        border-radius: 0;
        border-left: 1px solid $text-color;

        &.markdownaction_0 {
          border-radius: 5px 0 0 5px;
          border-left: 0;
        }

        &.last {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  &.show-preview {
    .markdown-field {
      display: none;
    }
  }
}
