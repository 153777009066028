@import "../../../styles/colors";

.education-detail-page {
  padding-bottom: 100px;

  .education-detail-container {
    margin: 0 auto;
    transition: width 0.3s ease;
    position: relative;
  }

  .education-detail-action {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .education-detail__header {
    max-width: 77%;
  }

  .waiting-list {
    margin-left: 15px;
  }

  .page-header {
    margin-bottom: 25px;
  }

  .confirmation-text {
    b {
      font-size: 2em;
      display: block;
    }
  }

  .axxes-points .small {
    margin-top: -10px;
  }

  .education-detail-credits-wrapper {
    margin-top: 15px;

    .axxes-points .small {
      font-size: 1.5em;
    }
  }

  .education-detail-credits {
    font-size: 2em;
  }

  .enroll-feedback {
    > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    .button {
      margin-top: 20px;
      margin-left: 5px;
    }

    .svg-inline--fa {
      font-size: 3em;
    }
  }

  .hidden {
    display: none;
  }

  .crossed-out {
    b {
      text-decoration: line-through;
    }
  }

  .education-requirements {
    max-width: 750px;
  }
}

.education-filled {
  color: $signal-red;

  .waiting-list {
    cursor: pointer;
  }
}

.enroll-modal {
  min-width: 600px;
  max-width: 900px;

  .education-detail-credits {
    margin-left: 15px;
    margin-right: 15px;
  }

  .exceeded-credits {
    color: $signal-red;
    font-weight: bold;
  }

  .requirements {
    .requirement {
      padding: 1rem 1rem 1rem 2rem;
      background-color: $background-color;
      border: 1px solid $grey-lighter;
      display: flex;
      align-items: center;

      label {
        margin-bottom: 0;

        input {
          transform: translateY(2px);
          margin-right: 5px;
        }
      }

      & + .requirement {
        border-top: 0;
      }
    }
  }

  .ticket-options {
    .ticket-option {
      margin-top: 15px;

      .registration-deadline {
        color: $signal-red;
        display: none;
        margin-left: 5px;
      }

      .ticket-option__name {
        border-left: 2px solid $primary;
        padding: 10px 10px;
        margin-left: 5px;
      }

      &.disabled {
        label {
          cursor: not-allowed;
          opacity: 0.4;
        }

        .registration-deadline {
          display: inline-block;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .education-detail-page {
    .education-detail-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 865px) {
  .education-detail-page {
    .education-detail {
      margin-bottom: 0;
    }

    .education-detail-action {
      position: relative;
      margin-left: 25px;
      margin-bottom: 50px;
    }

    .enroll-modal {
      width: 100% !important;
      min-width: auto !important;
    }
  }
}
